<template>
  <div class="home">
      <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Listing Presentations</h1>
        <div class="text-large">Coming Soon.</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
export default {
  name: "home",
  components: {
    Navigation
  },
  data() {
    return {};
  }
};
</script>
